/* apply to SectionContainer wrapper to align horizontaly */
.SectionHorizontalWrap {
    display: flex;
    gap: 8px;
}

.offer-create .anticon-calendar,
.offer-create .ant-picker-suffix {
    display: none;
}

/* apply to SectionContainer wrapper to align vecticaly */
.SectionVerticalWrap {
    gap: 8px;
    display: flex;
    flex-direction: column;
    height: fit-content;
}

.loginContainer {
    /* background: url(public/images/login-background.png); */
    background-color: black;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wechat-group-name .ant-select-arrow {
    display: none;
}

.lottery-tab {
    background-color: #fff;
    margin: 0px 24px;
    padding: 0px 24px;
}

.lottery-tab .ant-tabs-nav {
    margin-bottom: 0px;
}

.mp-table tbody .cell {
    text-align: center;
}

.menu-list {
    overflow: hidden;
}
.table-claims .ant-table-cell {
    padding: 0px !important;
}

.table-claims .urgent-claim {
    padding: 2px 8px !important;
    background-color: #ffa39e;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.table-claims .claim {
    padding: 2px 8px !important;
}

.table-refunds .urgent-refund {
    background-color: #FFCCC7 !important;
}

.menu-list:hover {
    overflow-y: scroll;
    overflow-x: hidden;
}

.loginContainer .window {
    width: 456px;
    height: 569px;

    background: #ffffff;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(59, 84, 114, 0.12);
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
}

.spin-loader {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.btnToTheEnd .ant-space-item,
.btnCenter .ant-space-item,
.centerMe .ant-space-item {
    width: max-content;
}

.FormRowWrapper {
    display: flex;
}

.FormCell {
    margin-top: 12px;
    margin-bottom: 4px;
}

.btnToTheEnd {
    width: 100%;
    justify-content: flex-end;
}

.btnCenter {
    width: 100%;
    justify-content: center;
}

.statsstts canvas {
    width: 100%;
}

.BorderWrap {
    border: 1px solid #f0f0f0;
    box-sizing: border-box;
    border-radius: 2px;
}

.navIconWrap {
    margin-top: 24px;
    margin-bottom: 10px;
}

.collapseIcon {
    color: #1890ff;
}

.navIconWrap.close {
    background-color: #ffa39e;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navIconWrap.open {
    padding-left: 24px;
    padding-right: 15px;
    padding-top: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.navBottomOptions {
    bottom: 0px;
    padding-bottom: 20px;
    position: fixed;

    transition-delay: 0.2s;
}

.navOptions {
    background-color: #fff !important;
    color: #000 !important;
}

.site-layout {
    transition-timing-function: ease-in-out;
    transition-duration: 0.2s;
    width: 100vw;
}

.formRow {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    grid-auto-rows: minmax(100px, auto);
}

.formCol {
    grid-row: 1;
}

.SectionContainer {
    background-color: #fff;
    margin: 8px;
    height: fit-content;
    flex: 1;
    padding: 24px;
}

.SectionContainer .TopBar {
    align-items: center;
    padding-left: 24px;
    display: flex;
}

.SectionContainer .Divider {
    margin-top: 0px;
    margin-bottom: 0px;
}

.SectionContainer .TopBar > h3 {
    margin-top: 18px;
    margin-bottom: 8px;
}

.SectionContainer .ContentContainer {
    padding: 0px 24px 24px 24px;
}

.TableTabsFilters .ant-tabs-nav {
    margin-bottom: 0px;
}

.ant-table-column-sorters {
    justify-content: flex-start;
}

.ant-table-column-sorters > .ant-table-column-title {
    flex: none;
    width: fit-content;
}

.ant-table-column-title {
    z-index: initial;
}

.ant-table-column-sorters {
    padding-right: 12px;
}

.ant-table-column-sorters > .ant-table-column-sorter {
    margin-left: 5px;
}

.customerDetailTab .ant-tabs-nav {
    padding: 0px 14px;
}

.customerDetailTab .ant-tabs-nav {
    margin: 0px;
}

.customerDetailTab .ant-tabs-content-holder {
    background-color: #f0f2f5;
}

.login-content,
.login-content div:nth-of-type(3),
.login-content .ant-form-item {
    width: 100%;
}

.login-content form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-content button {
    width: 182px;
    margin-top: 50px;
}

.bf-container {
    border: 1px solid #e6e7eb;
    box-sizing: border-box;
    border-radius: 2px;
}

.bf-content {
    height: 250px !important;
}

.ant-badge {
    width: max-content;
}

.centerMe {
    display: flex;
    align-items: center;
    justify-content: center;
}

.isBlue {
    background-color: #e6f7ff;
    height: 100%;
}

.clipboard.wrap {
    background-color: #000;
    position: fixed;
    transform: translate(0%, 0%);
    bottom: 15px;
    right: 15px;

    background: #434343;

    border: 1px solid #f0f2f5;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(27, 39, 58, 0.2);
    border-radius: 4px;
    transition: ease-out 0.25s;
}

.clipboard .buttonWrap {
    max-width: 309px;
    justify-content: flex-start;
    margin: 12px;
    gap: 5px;

    flex-wrap: wrap;
}

.clipboard .button {
    background-color: #595959;
    border-width: 0px;
    padding: 5px;
    width: 55px;
    border-radius: 4px;
}

.clipboard.wrap > .ant-space-item {
    height: 100%;
}

.clipboard.wrap.closed .anticon {
    transform: rotate(180deg);
}

.clipboard.wrap.closed {
    transform: translate(100%, 0%);
    bottom: 15px;
    right: 40px;
}

.close-arrow {
    background-color: #595959;
    border-radius: 4px;
    margin-left: 10px;
    padding: 5px 3px;
}

.multi-date-tags-wrap {
    display: flex;
    flex-wrap: wrap;
}

.multi-date-tags-wrap .multi-date-tags-item {
    margin-top: 8px;
}

.clipboard .buttonText {
    color: #fff;
    font-size: 11px;
    margin-bottom: 1px;
}

.ant-page-header-heading-extra {
    display: flex;
    align-items: center;
}

.ant-page-header-heading {
    width: 100%;
}

.tableTabs {
    background-color: #fff;
    height: 100%;
}

.tableTabs .ant-tabs-nav {
    margin-left: 24px;
    margin-right: 24px;
}

.form-wrapper-card > .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.form-wrapper-card label {
    white-space: nowrap;
}

.form-wrapper-card .check-group > div {
    flex-wrap: wrap;
}

.form-wrapper-card .ant-space,
.form-wrapper-card .ant-input-number,
.form-wrapper-card .ant-picker {
    width: 100% !important;
}

.customer-voucher-title {
    background-color: #fff;
    margin: 0px 24px;
    padding: 20px 24px;
}

.customer-voucher-button-wrap {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    padding: 0px 24px;
}

.ant-card.has-subtitle .ant-card-body {
    padding: 13px 24px;
}

.list-stats th::before,
.offer-live th::before {
    content: none !important;
}

.status-select {
    width: 100%;
}

.status-select .ant-select-selector {
    padding-left: 0px !important;
}

/* .ant-popover {
    padding: 0px;
    width: 124px;
    margin-top: -2px;
}

.ant-popover-inner-content {
    padding: 0px;
}

.ant-popover-arrow {
    display: none;
} */

.ant-table-cell {
    padding: 2px 8px !important;
    height: 50px;
}

.ant-table-cell.bold {
    font-weight: bold;
}

.offer-live .ant-select,
.offer-live .ant-input,
.offer-live .ant-input-number,
.offer-live .ant-select-selector,
.offer-live .ant-picker {
    width: 100%;
    height: 22px !important;
    border-radius: 2px;
}

.offer-live .ant-input-number-handler-wrap {
    width: 13px;
}

/* .offer-live .line-selected .ant-input-number-input,
.offer-live .ant-picker {
    padding: 0px 4px;
} */

.offer-live .ant-input-number-handler-down-inner,
.offer-live .ant-input-number-handler-up-inner {
    right: 0px;
}

.chart-card .ant-card-body {
    padding: 3;
}

.offer-live {
    font-size: 12px !important;
}

.offer-live th {
    padding: 0px !important;
}

.offer-live tr > th {
    padding: 0px 5px !important;
    line-height: 12px;
}

.offer-live th,
.offer-live td {
    padding: 0px 0px !important;
    border: 1px solid #f0f0f0;
    border-top: 0px !important;
    border-bottom: 0px !important;
}

.green {
    background: #67ed8c !important;
}

.green-text {
    color: #67ed8c !important;
}

.is-low,
.is-low .ant-typography {
    color: #ff7a45 !important;
}

.line.is-low {
    background-color: #fff2e8 !important;
}

.is-very-low,
.is-very-low .ant-typography {
    color: #ff4d4f !important;
}

.red-text {
    color: #ff4d4f !important;
}

.line.is-very-low {
    background-color: #fff1f0;
}

.is-highlight,
.is-highlight .ant-typography {
    background-color: #fffb8f !important;
}

.highlight-text {
    color: #fffb8f !important;
}

.total.is-low,
.total.is-very-low {
    font-weight: bold !important;
}

.offer-live .need-save .ant-input,
.offer-live .need-save .ant-select-selector {
    background-color: #fffb8f !important;
    color: #000 !important;
}

.is-low.ant-input-number,
.is-low.ant-input,
.is-low.ant-select {
    background-color: #ffa39e !important;
    color: #000 !important;
}

.is-alipay {
    background-color: #56ccf2 !important;
}

.tableWrap tr {
    height: 37px;
}

.offer-live .cell-selected,
.offer-live .head-selected {
    background-color: #d6e4ff !important;
}

.offer-live .cell-wrap,
.offer-live .cell-selected {
    width: 100%;
    height: 37px;
    padding: 0px 1px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.offer-live .cell-wrap > div,
.offer-live .cell-selected > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.offer-live .cell-selected {
    background-color: #d6e4ff !important;
    height: 37px;
}

.offer-live .ant-cascader-picker .ant-cascader-picker-label,
.offer-live .ant-cascader-picker .ant-cascader-input {
    padding: 0 11px 0 6px !important;
}

.offer-live .ant-cascader-picker-arrow {
    right: 4px !important;
}

.offer-live .ant-select-selection-item,
.offer-live .ant-input-number-input-wrap,
.offer-live .ant-select-selection-search,
.offer-live .ant-cascader-picker,
.offer-live .ant-input {
    font-size: 12px !important;
}

.offer-live .ant-select,
.offer-live .ant-input-number,
.offer-live .ant-input,
.offer-live .ant-picker,
.offer-live .ant-select,
.offer-live .ant-input-number,
.offer-live .ant-input,
.offer-live .ant-select-selector,
.offer-live .ant-picker {
    width: 100%;
    transition-duration: 0ms !important;
}

.line-selected {
    background-color: #d6e4ff !important;
}

.offer-live .line-selected .ant-select,
.offer-live .line-selected .ant-input-number,
.offer-live .line-selected .ant-input,
.offer-live .line-selected .ant-picker,
.offer-live .line-selected .ant-select,
.offer-live .line-selected .ant-input-number,
.offer-live .line-selected .ant-input,
.offer-live .line-selected .ant-picker {
    background-color: #fff;
    transition-duration: 0ms;
}

.offer-live .ant-input-disabled,
.offer-live .line-selected .ant-input-disabled,
.offer-live .ant-select-disabled,
.offer-live .line-selected .ant-select-disabled,
.offer-live .line-selected .ant-input-number-disabled,
.offer-live .ant-input-disabled:hover,
.offer-live .ant-select-disabled:hover,
.offer-live .ant-input-number-disabled:hover {
    background-color: #f5f5f5;
    width: 100%;
}

.offer-live tbody > tr:nth-child(even) {
    background-color: #fafafa;
}

.offer-live .ant-typography {
    text-align: center;
}

.offer-live .cell-wrap-bordered {
    width: 97%;
    margin: 0px;
}

.checkbox-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.offer-live .cell.checked {
    background-color: #e6f7ff;
}

.offer-live .cell.unchecked {
}

.offer-live .cell {
}

.offer-live .ant-badge-dot {
    top: 2px;
    right: 2px;
}

.offer-live .line {
    background-color: #fff;
}

/* .offer-live .cell {
    padding: 0px 1px !important;
} */

/* .offer-live .line-selected:hover {
    background-color: #d6e4ff !important;
} */

.offer-live td {
    transition: none !important;
    transition-delay: none !important;
}

.offer-live .ant-select-selection-item {
    padding-right: 14px !important;
}

.offer-live .ant-select-selector {
    padding: 0 4px !important;
}

.live-stats p,
.live-stats .ant-typography {
    font-size: 11px !important;
    margin: 0px;
    text-overflow: ellipsis;
    text-align: center;
}

.live-stats .stats-text-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 3px;
    height: 100%;
    width: 100%;

    /* background-color: red; */
}

.stats-text-wrap.stats-title {
    justify-content: flex-start;
}

.live-stats .stats-text-wrap.line {
    border-right: solid 1px #f0f0f0;
}

.live-stats > div:nth-child(2) {
    background-color: #fafafa;
}

.stats-line > div:nth-child(1) {
    display: block !important;
    padding-left: 5px !important;
    width: 85px !important;
}

.stats-title {
    text-align: left !important;
    font-weight: bold;
}

.live-stats .stats-line .ant-space-item {
    width: 113px;
    min-height: 26px;
}

.customer-search {
    width: 100%;
    padding: 0px 3px;
}

.qr-image {
    cursor: pointer;
    height: 30px;
}

.TableTabsFilters > div {
    padding: 0px 20px;
}

.qr-image:hover {
    background-color: #f0f2f5;
}

.qr-modal .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.qr-modal .ant-modal-body canvas {
    margin-bottom: 20px;
}

.spiner-search {
    width: 100%;
    margin-top: 5px;
}

.form-field-wrapper {
    width: 100%;
    display: grid;
    gap: 0px;
    grid-auto-rows: minmax(auto, auto);
    align-items: baseline;
}

.form-field-wrapper .ant-form-item {
    margin-bottom: 8px;
    margin-right: 8px;
}

.form-delete-grib-button {
    margin-bottom: 9px;
    align-self: end;
}

.form-wrapper-card .ant-typography {
    margin-top: 8px;
}
.form-wrapper-card .ant-btn-dashed {
    margin-bottom: 8px;
}

.nb-columns-1 {
    grid-template-columns: repeat(1, 1fr);
}
.nb-columns-2 {
    grid-template-columns: repeat(2, 1fr);
}
.nb-columns-3 {
    grid-template-columns: repeat(3, 1fr);
}
.nb-columns-4 {
    grid-template-columns: repeat(4, 1fr);
}
.nb-columns-5 {
    grid-template-columns: repeat(5, 1fr);
}
.nb-columns-6 {
    grid-template-columns: repeat(6, 1fr);
}
.nb-columns-8 {
    grid-template-columns: repeat(8, 1fr);
}
.nb-columns-10 {
    grid-template-columns: repeat(10, 1fr);
}
.nb-columns-11 {
    grid-template-columns: repeat(11, 1fr);
}
.nb-columns-12 {
    grid-template-columns: repeat(12, 1fr);
}

.col-1 {
    grid-column: 1;
}

.col-2 {
    grid-column: 2;
}

.col-3 {
    grid-column: 3;
}

.col-4 {
    grid-column: 4;
}

.col-5 {
    grid-column: 5;
}

.col-6 {
    grid-column: 6;
}

.col-7 {
    grid-column: 7;
}

.col-8 {
    grid-column: 8;
}

.col-9 {
    grid-column: 9;
}

.col-10 {
    grid-column: 10;
}

.col-11 {
    grid-column: 11;
}

.col-12 {
    grid-column: 12;
}

.col-1.size-2 {
    grid-column: 1 / 3;
}

.col-1.size-2 {
    grid-column: 1 / 3;
}

.col-1.size-3 {
    grid-column: 1 / 4;
}

.col-1.size-4 {
    grid-column: 1 / 5;
}

.col-1.size-5 {
    grid-column: 1 / 6;
}

.col-2.size-2 {
    grid-column: 2 / 4;
}

.col-2.size-3 {
    grid-column: 2 / 5;
}

.col-2.size-4 {
    grid-column: 2 / 6;
}

.col-3.size-2 {
    grid-column: 3 / 5;
}

.col-4.size-2 {
    grid-column: 4 / 6;
}

.col-4.size-3 {
    grid-column: 4 / 7;
}

.col-5.size-2 {
    grid-column: 5 / 7;
}

.col-6.size-5 {
    grid-column: 6 / 11;
}

.col-7.size-2 {
    grid-column: 7 / 9;
}

.col-9.size-2 {
    grid-column: 9 / 11;
}

.row-1 {
    grid-row: 1;
}

.row-2 {
    grid-row: 2;
}

.row-3 {
    grid-row: 3;
}

.row-4 {
    grid-row: 4;
}

.ant-card-body canvas {
    width: 100% !important;
}

.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: break-spaces;
    overflow: hidden;
    overflow-wrap: anywhere;
}

.option-refund {
    color: #FF7A45;
}